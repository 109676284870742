define(['jquery', 'countdown', 'siteObj'], function($, countdown, siteObj) {
  
  // Build an Array from the server time set in the siteObj.
  var dateArray = siteObj.features.countdown.servertime.split('-');
  var year = Number(dateArray[0]);
  var month = Number(dateArray[1]) - 1; //This needs to have 1 removed because in javascript months go from 0 - 11
  var date = Number(dateArray[2]);
  var hour = Number(dateArray[3]);
  var minute = Number(dateArray[4]);
  var second = Number(dateArray[5]);
  var dayOfTheWeek = dateArray[6];
  var oneDay = 86400000; // 1 Day in milliseconds
  var today = new Date(year, month, date, hour, minute, second, 0); // The time for today build from the server time
  var myNow = new Date().getTime();

  // Get countdown clock(s)
  var $countdownWidgets = $('.js-countdown-timer');

  $countdownWidgets.each(function() {
    var $countdownWidget = $(this);
    var settings = $countdownWidget.data();

    settings.cutOffTime = Date.parse(settings.cutOffTime);
    settings.serverTime = today;

    if (settings.localtimeoffset === true) {
      settings.serverTime = myNow;
    }

    if (settings.labels && settings.labels.indexOf(',') > -1) {
      settings.labels = settings.labels.split(',');
    }

    $countdownWidget.countdownTimer(settings);

  });

});
